<div class="row">
    <div class="col-lg-2 col-md-2 col-xl-2">

    </div>
    <div class="col-lg-8 col-md-8 col-xl-8">
      <div [routerLink]="['/']"  style="cursor: pointer;">
        <img [src]="logoImage" class="img-fluid mx-auto d-block" alt="Econômico">
        <p class="text-center">PIRAPOZINHO - SP | TARABAI - SP </p>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-xl-2">

    </div>
  </div>