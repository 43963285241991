import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openWhatsApp() {
    window.open('https://wa.me/message/RSV3CCBF2YHGH1', '_blank');
  }

  openFacebook() {
    window.open('https://www.facebook.com/supermercados.economico.9/', '_blank');
  }

  openInstagram() {
    window.open('https://www.instagram.com/supermercadoseconomico/', '_blank');
  }

}
