<footer style="padding: 8px;">
  <div class="row h-100 justify-content-center align-items-center mobile-center">
    <div class="col-lg-12 col-md-6 col-xl-9 mx-auto">
        <span class="align-middle" style="color: white;"> ACESSE NOSSAS REDES SOCIAIS CONFIRA NOSSAS OFERTAS E MUITO MAIS</span>

    </div>
    <div class="col-lg-12 col-md-2 col-xl-1  mobile-center"  style="cursor: pointer;">

        <i (click)="openWhatsApp()" class="fa fa-whatsapp fa-3x" style="color: #7dcff5;"></i>

    </div>
    <div class="col-lg-12 col-md-2 col-xl-1  mobile-center"  style="cursor: pointer;">
        <i (click)="openFacebook()" class="fa fa-facebook-square fa-3x" style="color: #7dcff5;"></i>
    </div>

    <div class="col-lg-12 col-md-2 col-xl-1  mobile-center"  style="cursor: pointer;">
        <i (click)="openInstagram()" class="fa fa-instagram fa-3x" style="color: #7dcff5;"></i>

    </div>
  </div>

</footer>
